<template>

  <!-- <input v-model="searchId" @keyup.enter="loadData" placeholder="Strain ID" /> -->
  <input v-model="searchName" @keyup.enter="loadData" placeholder="Strain name" />

  <UiButton @click="loadData">Reload</UiButton>

  <ClientOnly>
    <div class="cnt" v-if="data?.edges">
      <VisNetwork
        :nodes="data.nodes"
        :edges="data.edges"
        :options="options"
        :clusterOptions="clusterOptions"
        @click="clickNode"
      />
    </div>
  </ClientOnly>
</template>

<script setup>
import { ref } from 'vue'
import VisNetwork from '@/components/VisNetwork.vue'
import { useLazyAsyncData } from '#app'

const { $api } = useNuxtApp()

const searchId = ref(0)
const searchName = ref('')
const data = ref(null)

const options = ref({
  layout: {
    hierarchical: {
      direction: 'UD',
      sortMethod: 'directed'
    }
  },
  edges: {
    arrows: {
      to: { enabled: false }
    }
  },
  nodes: {
    shape: 'box',
    size: 20,
    font: {
      size: 14,
      color: '#000000'
    },
    color: {
      background: '#ffffff',
      border: '#2B7CE9',
      highlight: {
        background: '#e2e2e2',
        border: '#2B7CE9'
      }
    }
  },
  physics: {
    enabled: false
  },
  interaction: {
    hover: true
  },
  height: '100%'
})

const clusterOptions = ref({
  joinCondition: (child) => child.group === 'default', // Настройте условие кластеризации
  clusterNodeProperties: {
    label: 'Кластер'
  }
})

const loadData = async () => {
  data.value = await $api.getStrainsTree(searchId.value, searchName.value)
}
 

const clickNode = (id, label) => {
  console.log(`Вы кликнули на узел: ${id}`)
  console.log(`Вы кликнули на узел: ${label}`)
  // searchId.value = id
  searchName.value = label
  loadData()
}

// const { data } = await useLazyAsyncData('data', loadData)

</script>

<style scoped>
.cnt{
  height: 100vh;
  width: 100%;
}
</style>