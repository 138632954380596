<template>
  <div class="network" ref="network"></div>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue'
import { Network } from 'vis-network/standalone/esm/vis-network'

const emits = defineEmits(['click'])

// Определение пропсов
const props = defineProps({
  nodes: {
    type: Array,
    required: true
  },
  edges: {
    type: Array,
    required: true
  },
  options: {
    type: Object,
    default: () => ({})
  },
  clusterOptions: { // Добавляем опции кластеризации
    type: Object,
    default: () => ({
      joinCondition: (child) => child.group === 'default', // Пример условия
      clusterNodeProperties: {
        label: 'Кластер'
      }
    })
  }
})

// Ссылка на контейнер сети
const network = ref(null)
let networkInstance = null

// Инициализация сети при монтировании компонента
onMounted(() => {
  if (network.value) {
    const data = {
      nodes: props.nodes,
      edges: props.edges
    }
    networkInstance = new Network(network.value, data, props.options)

    // Пример добавления обработчика события клика на узел
    networkInstance.on('click', params => {
      if (params.nodes.length > 0) {
        const nodeId = params.nodes[0]
        const node = props.nodes.find(n => n.id === nodeId)
        if (node) {
          // alert(`Вы кликнули на узел: ${node.label}`)
          emits('click', node.id, node.label)
        }
      }
    })

    // Применение кластеризации после инициализации
    applyClustering()
  }
})

// Функция кластеризации
const applyClustering = () => {
  if (!networkInstance) return

  networkInstance.cluster(props.clusterOptions)
  
  // Дополнительно можно настроить автоматическую кластеризацию при изменении данных
}

// Обновление сети при изменении пропсов
watch(
  () => [props.nodes, props.edges, props.options],
  ([newNodes, newEdges, newOptions]) => {
    if (networkInstance) {
      networkInstance.setData({
        nodes: newNodes,
        edges: newEdges
      })
      networkInstance.setOptions(newOptions)
      
      // Перекластеризация после обновления данных
      applyClustering()
    }
  },
  { deep: true }
)

// Очистка при размонтировании компонента
onBeforeUnmount(() => {
  if (networkInstance) {
    networkInstance.destroy()
  }
})
</script>

<style scoped>
.network{
  height: 100%;
  width: 100%;
}
</style>